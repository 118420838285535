import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { getBillingData } from "../services";
import { useAuth } from '../hooks';
import RoleNavMenu from "../components/RoleNavMenu";
import Spinner from "../components/Spinner";
import Select from 'react-select';
import BlackButton from "../components/BlackButton";
import PaymentsModal from "../components/PaymentsModal";
import PageContainer from "../components/PageContainer";

const Container = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: row;
    .content {
      flex: 1;
      position: relative;
      padding: 0 0.5rem;
      font-family: Roboto;
      font-weight: 400;
      .data-container {
        >div {
          font-size: 1rem;
          line-height: 1.5;
          &.pay-as-you-go {
            font-weight: 500;
            font-size: 1.25rem;
          }
          &.credit-label {
            margin-top: 1rem;
            font-weight: 400;
            font-size: 1rem;
            position: relative;
            width: fit-content;
            span {
              font-size: 0.8rem;
              /* margin-top: -0.8rem; */
              display: block;
              position: absolute;
              top: 0.1rem;
              right: -1.2rem;
            }
          }
          &.credit-balance {
            margin-top: 0;
            font-size: 2rem;
            /* font-family: 'Arial'; */
            margin-bottom: 1rem;
            line-height: 1.25;
          }
        }
        button {
          margin-top: 0.5rem;
        }
      }
      .spinner-container {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        left: 15rem;
        justify-content: center;
        display: flex;
        align-items: center;
      }

    }
`

const Billing = () => {
  const [ loading, setLoading ] = useState(false);
  const [ data, setData ] = useState(null);
  const isLoading = useRef(false);
  const [isPaymentsModalOpen, setPaymentsModalOpen] = useState(false);
  useAuth();

  useEffect(() => {
    const getBilling = async () => {
      isLoading.current = true;
      setLoading(true);
      try {
        const data = await getBillingData();
        setData(data);
      } catch (err) {
        console.log(err);
      } finally {
        isLoading.current = false;
        setLoading(false);
      }
    }  
    if (!isLoading.current) {
      getBilling();
    }
  }, [])

  const onPaymentsModalClose = () => {
    setPaymentsModalOpen(false);
  }

  const onAddMoneyClick = () => {
    setPaymentsModalOpen(true);
  }

  const updateBalance = (data) => {
    setData(data);
  }

  return (
    <>
      <PageContainer title={'Billing'} selected={'billing'}>
        <Container>
          <div className="content">
            {
              loading ?
              <div className="spinner-container"><Spinner/></div> :
              <div className="data-container">
                {
                  data &&
                  <>
                    <div className="pay-as-you-go">Pay as you go</div>
                    <div className="credit-label">Credit balance <span>&#9432;</span></div>
                    <div className="credit-balance">${data.credit_balance}</div>
                    <BlackButton onClick={onAddMoneyClick}>Add to credit balance</BlackButton>
                  </>
                }
              
              </div>
            }
          </div>   
      </Container>
      </PageContainer>
      <PaymentsModal
        updateBalance={updateBalance}
        isOpen={isPaymentsModalOpen}
        onClose={onPaymentsModalClose}
      />
    </>
  );
};

export default Billing;