import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import Select from 'react-select/creatable';
import { useAtom } from 'jotai';
import { skillAtom } from "../atoms";

const Container = styled.div`
.skill-select-label {
  top: 0.75rem;
  opacity: 1;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: black;
  padding: 0;
}
.skill-select {
  .skill-select__control {
    border: none !important;
    border-bottom: 1px solid var(--color-gray0) !important;
    box-shadow: none !important;
    .skill-select__value-container {
      padding: 0;
      min-height: 3.5rem;
      .skill-select__multi-value {
        background: white;
        border: 1px solid black;
        >div {
          background: white;
          svg {
            opacity: 0.6;
          }
        }
      }
    }
    .skill-select__multi-value__remove:hover {
      background-color: #ccc;
      color: black;
    }
    .skill-select__placeholder {
      color:  var(--color-gray1);
      font-size: 1rem;
      padding: 0;
    }
  }
  .skill-select__menu {
    margin-top: -1px !important;
    padding: 0 !important;
    .skill-select__menu-list {
      padding: 0 !important;
      .skill-select__option {
        font-size: 0.9rem !important;
      }
    }
  }
}
`

const SkillSelector = ({ placeholder, value: initialValue, onChange }) => {
  const selectInputRef = useRef();
  const [skills] = useAtom(skillAtom);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(initialValue || []);
  const [focused, setFocused] = useState(false);

  const createOption = (label) => ({
    label: label.toLowerCase().trim(),
    value: label.toLowerCase().trim(),
  });

  useEffect(() => {
    if (skills) {
      setOptions(skills.map(item => createOption(item)))
    }
  }, [skills])

  const onSelectChange = (value) => {
    const stringValueArr = value.map(item => item.value);
    setValue(stringValueArr);
    onChange(stringValueArr);
  }

  const isValidNewOption = (inputValue, value, options) => {
    if (!inputValue?.trim()) return false;
    const fromattedInputValue = inputValue.toLocaleLowerCase().trim();
    if (value?.find(item => item.value === fromattedInputValue)) return false;
    if (options?.find(item => item.value === fromattedInputValue)) return false;
    return true;
  }

  const formatValue = (stringValue) => {
    const objectValue = [];
    const formattedValue = stringValue.filter(item => !!item).map(item => item.toLocaleLowerCase().trim());
    formattedValue.forEach(item => {
      const option = options.find(a => a.value === item);
      if (option) {
        objectValue.push(option)
      } else {
        objectValue.push(createOption(item));
      }
    });
    return objectValue;
  }

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    setOptions((prev) => [...prev, newOption]);
    setValue((prev) => {
      const newValue = [...prev, newOption.value];
      onChange(newValue);
      return newValue;
    });
  };

  const onFocus = () => {
    setFocused(true);
  }
  const onBlur = () => {
    setFocused(false);
  }

  return (
    <Container>
      {
        value.length > 0 &&
        <label className="skill-select-label">{placeholder}</label>
      }
      <Select
        className="skill-select"
        classNamePrefix="skill-select" 
        closeMenuOnSelect={false}
        placeholder={placeholder} 
        ref={selectInputRef}
        isValidNewOption={isValidNewOption}
        isDisabled={false}
        createOptionPosition="first"
        formatCreateLabel={(value) => `Add "${value?.toLocaleLowerCase()}"`}
        onChange={onSelectChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={formatValue(value)}
        onCreateOption={handleCreate}
        isMulti
        options={options}
        maxMenuHeight={'10.2rem'}
      />
    </Container>
  );  
}

export default SkillSelector;
