import React from "react";
import styled from 'styled-components';

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 1.6rem;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 0.9rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 2rem;

  span.checkbox-label {
    font-size: 0.8rem;
    font-weight: 500;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: white;
    border: 1px solid black;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: white;
  }

  /* When the checkbox is checked, add a teal background */
  input:checked ~ .checkmark {
    background-color: white;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 0.3rem;
    top: 0.1rem;
    width: 0.3rem;
    height: 0.6rem;
    border: solid black;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const StyledCheckbox = ({ name, label, checked, onChange, children, ...props }) => {
  return (
    <Container { ...props }>
      <input 
        id={name}
        name={name}
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <span className="checkmark"></span>
      <span className="checkbox-label">{children}</span>
    </Container>
  );
}

export default StyledCheckbox;