import React from "react";
import { SpinnerCircularFixed } from "spinners-react";

const Spinner =  ({
  size='3rem', 
  // color="rgba(0, 0, 0, 0.5)", 
  color="#121212", 
  secondaryColor="rgba(0, 0, 0, 0.1)",
  ...props
}) =>  <SpinnerCircularFixed 
    size={size} 
    thickness={250} 
    speed={100} 
    color={color} 
    secondaryColor={secondaryColor} 
    {...props}/>

export default Spinner;