import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import RoleListItem from "../components/RoleListItem";
import RoleModal from "../components/RoleModal";
import PaginationControl from "../components/PaginationControl";
import { getRoles, createRole, saveRole } from "../services";
import { useAuth } from '../hooks';
import RoleNavMenu from "../components/RoleNavMenu";
import Spinner from "../components/Spinner";
import BlueButton from "../components/BlueButton";
import PageContainer from "../components/PageContainer";


const Container = styled.div`
    margin-top: 7rem;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    box-sizing: border-box;
    align-self: flex-start;
    max-width: 60rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    .content {
      flex: 1;
      position: relative;
    }
    .no-roles {
      font-size: 0.9rem;
      margin-top: -0.2rem;
      max-width: 39rem;
      font-family: Roboto;
      font-weight: 400;
      text-align: left;
    }
    .filter-container {
      width: 14rem;
      margin-bottom: 2rem;
    }
    .spacer {
      flex: 1;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
    }
    .spinner {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      left: 15rem;
      justify-content: center;
      display: flex;
      align-items: center;
    }
`

const Roles = () => {
  const [ roles, setRoles ] = useState([]);
  const [ page, setPage ] = useState(1);
  const [ isRoleModalOpen, setRoleModalOpen ] = useState(false);
  const rolesPerPage = 10;
  const [ roleData, setRoleData ] = useState();
  const [ loading, setLoading ] = useState(true);
  const isLoading = useRef(false);
  useAuth();

  const onPageSelect = (n) => {
    window.scrollTo(0,0);
    setPage(n);
  }

  useEffect(() => {
    if (!isLoading.current) {
      fetchRoles();
    }
  }, [])

  const fetchRoles = async () => {
    setLoading(true);
    isLoading.current = true;
    try {
      const data = await getRoles();
      if (data) {
        setRoles(data);
      }
    } catch(err) {
      setRoles([])
      // TODO this should notify the user that a problem occurred
    } finally {
      setLoading(false);
      isLoading.current = false;
    }
  }

  const sortRoles = (roles) => {
    setRoles([...roles.filter(a => a.is_active), ...roles.filter(a => !a.is_active)])
  }

  const onRoleSubmit = async (data) => {
    const newRole = await createRole(data);
    sortRoles([ newRole, ...roles ]);
    setRoleModalOpen(false);
    setRoleData(null);
  }

  const onRoleSave = async (data) => {
    let role = { id: roleData.id, ...data };
    role = await saveRole(role);
    const newRoles = [ role, ...roles.filter(a => a.id !== role.id) ];
    sortRoles(newRoles);
    setRoleModalOpen(false);
    setRoleData(null);
  }

  const onRoleModalClose = () => {
    setRoleModalOpen(false);
    setRoleData(null);
  }

  const openRoleModal = (item) => {
    setRoleModalOpen(true);
  }

  const onRoleItemClick = (data) => {
    setRoleData(data);
    setTimeout(() => {
      setRoleModalOpen(true);
    }, 50);
  }

  const renderContent = () => {
    if (loading) return (
      <div className="content">
        <div className="spinner">
          <Spinner/>
        </div>
      </div>
    );
    return  <div className="content">
      <BlueButton onClick={ openRoleModal } style={{marginBottom: '1rem'}}>Add Role</BlueButton>
      {
        roles.length === 0 &&
        <div className="no-roles">Please add open software engineering roles at your company. Once roles are added, 
        you will be able to upload/screen applicant resumes.</div>
      }
      {
        roles.slice((page-1)*rolesPerPage, page*rolesPerPage)
        .map((item, i) => <RoleListItem key={i} data={item} onClick={onRoleItemClick}/>)
      }
      <div className="spacer"/>
      {
        !loading.current &&
        <div className="controls">
          <div/>
          {
            roles.length < rolesPerPage ?
            <div/> :
            <PaginationControl 
              selected={page} 
              total={Math.ceil(roles.length/rolesPerPage)} 
              onClick={onPageSelect}/>
          }
        </div>
      }
    </div>
  }

  return (
    <>
    <PageContainer title={'Roles'} selected={'roles'}>
      <Container>
      { renderContent() }
      </Container>
    </PageContainer>
    <RoleModal 
      isOpen={ isRoleModalOpen }
      data={ roleData }
      onSubmit={ roleData ? onRoleSave : onRoleSubmit }
      onClose={ onRoleModalClose }
    />
    </>
  );
};

export default Roles;