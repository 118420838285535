import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { firebaseAuth } from '../services/firebase';
var mobile = require('is-mobile');

const useAuth = (shouldBeLoggedIn = true) => {
    const navigate = useNavigate();
    const [user, setUser] =  useState(null);

    useEffect(() => {
        let unsubscribe;
        const checkAuth = async () => {
            unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
                if (shouldBeLoggedIn && !user) {
                    unsubscribe();
                    navigate('/');
                } else if (!shouldBeLoggedIn && user) {
                    unsubscribe();
                    if (!mobile()) {
                      navigate('/roles');
                    } 
                } 
                setUser(user);
            });
        } 
        checkAuth();
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, [navigate, shouldBeLoggedIn]);

    return user;
}

export default useAuth;