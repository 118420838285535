import React from 'react';
import styled from 'styled-components';
import 'react-tabs/style/react-tabs.css';
import { AiFillLinkedin, AiFillGithub } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip'

const Container = styled.div`
  margin: 2rem auto;
  height: 100%;
  max-width: 60rem;

  .resume-records {
    display: flex;
    >div {
      flex: 0.5;
      &.primary-records {
        label {
          min-width: 15rem;
          display: inline-block;
        }
      }
      &.secondary-records {
        label {
          min-width: 12rem;
          display: inline-block;
        }
      }
    }
    .record {
      margin-bottom: 0.3rem;
      font-size: 1rem;
      a {
        color: black !important;
      }
      label {
        font-weight: 500;
        margin-right: 0.2rem;
        &.aligned {
          min-width: 15rem;
          display: inline-block;
        }
      }
    }
  }
  .work-record {
    margin-top: 2rem;
    .work-record-header {
      display: flex;
      .company-name {
        font-weight: 600;
      }
      .domain {
        margin-left: 0.3rem;
      }
      .spacer {
        flex: 1;
      }
      .tenure {
        color: var(--color-blue1old);
        font-weight: 600;
      }
    }
    .description {
      margin-top: 0.3rem;
      font-size: 0.9rem;
    }
  }
  .latest-jobs {
    margin: 1.5rem auto 0;
    /* width: fit-content; */
    h3 {
      font-weight: 600;
      margin: 0;
      text-align: center;
    }
    table {
      border-collapse: collapse;
      border: 1px solid var(--color-gray2);
      tr, th, td {
        border: 1px solid var(--color-gray2);
        &.hidden {
          display: none;
        }
      }
      th, td {
        padding: 0.5rem;
        box-sizing: border-box;
        &.expand-button {
          width: 2rem;
          text-align: center;
          cursor: pointer;
        }
      }
      th {
        text-align: center;
        font-weight: 600;
        background-color: var(--color-gray0)
      }
      margin-top: 1rem;
    }
  }
  
  .school-record {
    margin-top: 2rem;
    .spacer {
      flex: 1;
    }
    text-transform: capitalize;
    .school-record-header {
      display: flex;
      text-transform: capitalize;
      .school-name {
        font-weight: 600;
      }
      .graduate-year {

      }
    }
    .school-record-footer {
      margin-top: 0.2rem;
      display: flex;
    }
  }
  @media screen and (max-width: 40rem) {
    .resume-records {
      flex-direction: column;
    }
    .badges {
      div {
        div {
          font-size: 0.7rem;
          padding: 0.5rem;
          width: 10rem;
          min-width: 10rem;
        }
      }
    }
  }
`

const Pill = styled.div`
  margin: -0.15rem 0 0 0.5rem;
  font-size: 0.7rem;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  box-sizing: border-box;
  font-weight: 600;
  height: fit-content;
  &.green {
    background-color: var(--color-green1);
    color: white;
  }
  &.blue {
    background-color: var(--color-blue1old);
    color: white;
  }
  &.red {
    background-color: var(--color-red2);
    color: white;
  }
  &.grey {
    background-color: var(--color-gray0);
  }
`
const Gap = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray0);
  border-radius: 0.3rem;
  font-weight: 600;
  width: 100%;
`


const renderSchoolRecord = (record) => (
  <div className='school-record'>
     <div className='school-record-header'>
      <div className='school-name'>{record.name}</div>
      {
        record.us_rank &&
        <Pill className='blue'>{record.us_rank}</Pill>
      }
      <div className='spacer'/>
      <div className='graduate-year'>{record.graduate_year}</div>
     </div>
     <div className='school-record-footer'>
      {record.degree}, {record.major}
     </div>
  </div>
)

const renderWorkRecord = (role) => {
  if (role.gap_in_months) {
    return <Gap style={{height: role.gap_in_months < 12 ? '3rem' : '4rem'}}>
        {role.length_label}
    </Gap>
  }
  return (
    <div className='work-record' style={{marginTop: role.same_company ? '0rem' : '2rem'}}>
      {
        !role.same_company &&
        <div 
          className='work-record-header' 
          style={{
            marginBottom: role.was_promoted ? '-0.1rem' : 0, 
            fontSize: '1.1rem',
            textTransform: 'capitalize'
          }}>
          <div className='company-name'>{role.company_name}</div>
          {
            role.domain &&
            <Pill className='blue' style={{marginTop: '0rem', marginLeft: '0.5rem'}}>{role.domain}</Pill>
          }
          <div className='spacer'/>
        </div>
      }
      <div 
        className='work-record-header' 
        style={{marginTop: '0.5rem'}}
        >
        <div className='company-name'>{role.title}</div>
        {
          role.se_role_type &&
          <Pill className='blue'>{role.se_role_type}</Pill>
        }
        {
          role.was_promoted &&
          <Pill className='blue'>Promoted!</Pill>
        }
        
        <div className='spacer'/>
        <div 
          data-tooltip-id={role.from_and_to && "tooltip"}
          data-tooltip-content={role.from_and_to} 
          data-tooltip-place="top"
          className='tenure'>
            {role.tenure}
        </div>
      </div>
      <div 
        className='description' 
        style={{flex: 1 }} 
        dangerouslySetInnerHTML={{__html: role?.description}}/>
    </div>
  )
}

const ParsedResume = ({ data }) => {
  return <Container>
    {
      data &&
      <>
      <div className="resume-records" style={{alignItems: 'center', display: 'flex', flex:1, flexDirection: 'column'}}>
        <div className='record' style={{fontSize: '1.2rem'}}>{data?.name}</div>
        {
          data?.email &&
          <div className='record' style={{fontSize: '1rem'}}>
            <span style={{paddingRight: '0.2rem'}}>E:</span>
            <a 
              target='_blank' 
              rel='noreferrer' 
              href={`mailto: ${data?.email}`}>
                {data?.email}
            </a>
          </div>
        }
        {
          data?.phone &&
          <div className='record' style={{fontSize: '1rem'}}>
            <span style={{paddingRight: '0.2rem'}}>T:</span> 
            <a 
              target='_blank' 
              rel='noreferrer' 
              href={`tel: ${data?.phone}`}>
                {data?.phone}
            </a>
          </div>
        }
        {
          (data?.linkedin || data?.github) &&
          <div className='record' style={{fontSize: '1rem'}}>
            {
                data?.linkedin &&
                <a 
                target='_blank' 
                rel='noreferrer' 
                href={data?.linkedin}>
                    <AiFillLinkedin size={'1.2rem'}/>
              </a>
            }
            {
                data?.github &&
                <a 
                target='_blank' 
                rel='noreferrer' 
                href={data?.github}>
                  <AiFillGithub  size={'1.2rem'}/>
              </a>
            }
          </div>
        }
      </div> 
      <br/>
      <br/>  
      <div className="resume-records">
        <div className="primary-records">
            <div className='record'><label>Country of Residence:</label> {data?.country_of_residence}</div>
            <div className='record'><label className='aligned'>Total YoE (without school): </label> {data?.total_yoe}</div>
            <div className='record'><label className='aligned'>IC YoE: </label> {data?.ic_yoe}</div>
            <div className='record'><label className='aligned'> Manager YoE:</label> {data?.manager_yoe}</div>
            <br/>
          </div>
          <div className="secondary-records">
            <div className='record'><label>Immigration Status:</label> {data?.immigration_status}</div>
          </div>
        </div>
        {
          !!data?.work_experience?.length && <>
            <div className='latest-jobs'>
              <h3>Work Experience</h3>
                {
                  data?.work_experience?.map((role) => renderWorkRecord(role))
                }
            </div>
            <br/>
            <br/>
          </>
        }
        {
          !!data?.school_work_experience?.length && <>
            <div className='latest-jobs'>
            <h3>School Work Experience </h3>
              {
                data?.school_work_experience?.map((role) => renderWorkRecord(role))
              }
            </div>
            <br/>
            <br/>
          </>
        }
        {
          !!data?.school_details?.length && <>
            <div className='latest-jobs'>
            <h3>Education </h3>
              {
                data?.school_details?.map((role) => renderSchoolRecord(role))
              }
            </div>
            <br/>
            <br/>
          </>
        }
        <br/>
        <Tooltip id="tooltip" style={{background: '#555'}}/>
      </>
    }
  </Container>
}

export default ParsedResume;