import React, { useState } from "react";
import styled from 'styled-components';
import { useAuth } from "../hooks";
import { useAtom } from 'jotai';
import { loadingAtom, loginModalAtom } from "../atoms";
import PageContainer from "../components/PageContainer";
import { validateEmail, p2r } from "../utils";
import { ReactComponent as EyeIcon } from "../assets/images/eye.svg";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { ReactComponent as GoogleIcon } from "../assets/images/google.svg";
import WhiteButton from "../components/WhiteButton";
import { loginFirebaseUser, firebaseEmailSignup, loginGoogleUser } from "../services/firebase";
import BlackButton from "../components/BlackButton";
import GreyBar from "../components/GreyBar";
import { useNavigate } from 'react-router';


const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  flex:1;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  .form-wrapper {
    width: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .grey-bar {
      margin-top: ${p2r(15)};
    }
    .form-separator {
      display: flex;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: ${p2r(10)};
      width: 100%;
      height: ${p2r(24)};
      margin-top: ${p2r(10)};
      .form-separator-line {
        height: 1px;
        flex: 1;
        background-color: #A7A6A6;
      }
    }
  }
  h3 {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
  }
  .invalid-creds {
    color: var(--color-red-1);
    font-weight: 400;
    margin-bottom: 1.5rem;
    text-align: left;
    width: 100%;
    margin-top: -0.5rem;
    font-size: 0.9rem;
  }
  .invalid-creds2 {
    color: var(--color-red-1);
    font-weight: 400;
    margin-bottom: 0rem;
    text-align: left;
    width: 100%;
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  .contact-form {
    width: 100%;
    position: relative;
    margin: ${p2r(20)} 0;
    label {
      /* Email address */
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: ${p2r(12)};
      line-height: ${p2r(18)};
      color: #6D6E6F;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }
    .password-wrapper {
      position: relative;
      svg {
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        bottom: 0.6rem;
        right: 1rem;
      }
    }

    input, textarea {
      all: unset;
      display: block;
      width: 100%;
      border: 1px solid #9A9999;
      border-radius: 3px;
      height: ${p2r(42)};
      padding: 0 ${p2r(20)};
      margin-top: ${p2r(5)};
      font-size: 1rem;
      line-height: 1.5rem;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      box-sizing: border-box;

      font-style: normal;
      font-weight: 500;
      /* identical to box height, or 150% */
      color: #000000;
      opacity: 0.7;

      &[name="password"] {
        padding: 0 3rem 0 1rem;
      }
      
      &::placeholder {
        color: var(--color-gray-0);
        font-size: 1rem;
      }
      &.error {
        border: 1px solid var(--color-red-1);
      }
    }
    textarea::placeholder {
      transform: translateY(1rem);
    }
    .message-received {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      display: flex;
      justify-content: center;
      padding: 4rem 0 0 0;
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  button {
    width: 100%;
    height: 2.5rem;
    text-align: center;
    justify-content: center;
    font-size: 1rem;
  }
  .signup-link {
    cursor: pointer;
    color: var(--color-blue1);
    margin-bottom: 0;
  }
  .setup-complete-message {
    text-align: center;
    padding: 3rem 0 5rem;
  }
  .social-sign-in {
    display: flex;
    margin-top: ${p2r(40)};
    justify-content: center;
    align-items: center;
    border: 1px solid #9A9999;
    border-radius: 6px;
    height: ${p2r(50)};
    font-weight: 500;
    font-size: ${p2r(18)};
    line-height: ${p2r(18)};
    position: relative;
    cursor: pointer;
    svg {
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: calc(${p2r(25)} - 1rem);
      left: 0.6rem;
    }
  }
`;

const Login = () => {
  const navigate = useNavigate();
  const [ , setLoading ] = useAtom(loadingAtom);
  const [isSignup, setIsSignup] = useState(false);
  const [formData, setFormData] = useState({
    email: { value: '', error: false },
    password: { value: '', error: false }
  });
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [validationMessage, showValidationMessage] = useState(false);
  const [validationMessage2, showValidationMessage2] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);
  useAuth(false);

  const validateFormData = (data) => {
    const validatedData = {...data};
    let isValid = true;
    Object.keys(validatedData).forEach(key => {
      if (isSignup && key !== "email") return;
      const value = validatedData[key].value.trim(); 
      if(value === '') {
        validatedData[key].error = true;
        isValid = false;
      } else if (key === "email" && !validateEmail(value)) {
        validatedData[key].error = true;
        isValid = false;
      } else {
        validatedData[key].error = false;
      }
    });
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = () => {
    showValidationMessage(false);
    showValidationMessage2(false);
    setFormData({
      email: { value: '', error: false },
      password: { value: '', error: false }
    });
  }


  const onInputChange = (e) => {
    showValidationMessage(false);
    const data = {...formData};
    data[e.currentTarget.name].value = e.currentTarget.value;
    validateFormData(data);
  }

  const onLoginClick = async () => {
    if (validateFormData(formData)) {
      setLoading(true);
      const email = formData?.email?.value || '';
      const password = formData?.password?.value || '';
      const user = await loginFirebaseUser(email, password);
      if (user === "failure") {
        setLoading(false);  
        setInvalidCredentials(true);
        showValidationMessage(true);
        showValidationMessage2(false);
        console.log("Login failed");
      } else {
        clearFormData();
        setLoading(false);  
        setInvalidCredentials(false);
        console.log(`You are now logged in as: ${user.email}`);
        window.location.href = '/roles';
        //navigate('/roles');
      }
    } else {
      showValidationMessage(true);
    }
  } 

  const onGoogleClick = async () => {
    const user = await loginGoogleUser();
    if (user === "failure") {
      showValidationMessage(false);
      showValidationMessage2(true);
      console.log("Login failed");
    } else {
      clearFormData();
      setLoading(false);  
      setInvalidCredentials(false);
      console.log(`You are now logged in as: ${user.email}`);
      window.location.href = '/roles';
      //navigate('/roles');
    }
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13 ) {
      onLoginClick();
    }
  }

  const onEyeIconClick = () => {
    setPasswordHidden(!passwordHidden);
  }
 
  return (
    <>
      <PageContainer 
        selected={'login'}
        title={'Login'}
        hideNavPanel={true}
        hideNavBar={true}
      >
        <Container>
          <div className="form-wrapper">
            <h3>Log in to Honest Resume</h3>
            <GreyBar/>
            <WhiteButton className='social-sign-in' onClick={onGoogleClick}>
              <GoogleIcon/> Sign in with Google
            </WhiteButton>
            <div className="form-separator">
              <div className="form-separator-line"/>
              <div>or</div>
              <div className="form-separator-line"/>
            </div>

            <form className="contact-form" >
              <label>Email address</label>
              <input 
                name="email"
                placeholder="Email"
                type="text"
                onChange={onInputChange}
                onKeyDown={onKeyDown}
                className={formData?.email?.error ? 'error' : ''}
              />
              <label style={{marginTop: p2r(10), display: 'block'}}>Password</label>
              <div className="password-wrapper">
                <input 
                  name="password"
                  placeholder="Password"
                  type={passwordHidden ? "password" : "text"}
                  onChange={onInputChange}
                  onKeyDown={onKeyDown}
                  className={formData?.password?.error ? 'error' : ''}
                />
                {
                  passwordHidden ? 
                  <BsEye onClick={onEyeIconClick}/> : 
                  <BsEyeSlash onClick={onEyeIconClick}/>
                }          
              </div>
            
            </form>
            {
              invalidCredentials &&
              validationMessage &&
              <div className="invalid-creds">
                Invalid login or password
              </div>
            }
            <BlackButton onClick={onLoginClick}>Continue</BlackButton>
            {
              validationMessage2 &&
              <div className="invalid-creds2">
                Coul'd not find your Honest Resume account
              </div>
            }
            </div>
        </Container>
      </PageContainer>
    </>
  );
};

export default Login;
