import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useAuth } from "../hooks";
import { useAtom } from 'jotai';
import { loadingAtom } from "../atoms";
import PageContainer from "../components/PageContainer";
import { validateEmail, p2r } from "../utils";
import { ReactComponent as GoogleIcon } from "../assets/images/google.svg";
import WhiteButton from "../components/WhiteButton";
import { 
  loginFirebaseUser, 
  completeFirebaseEmailSignup, 
  loginGoogleUser, 
  firebaseEmailTrial 
} from "../services/firebase";
import BlackButton from "../components/BlackButton";
import GreyBar from "../components/GreyBar";
import { doesEmailExist } from "../services";

var mobile = require('is-mobile');
const IS_MOBILE = mobile();

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  flex:1;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  .grey-bar {
    margin-top: ${p2r(15)};
  }
  .form-wrapper {
    width: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .form-separator {
      display: flex;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: ${p2r(10)};
      width: 100%;
      height: ${p2r(24)};
      margin-top: ${p2r(20)};
      .form-separator-line {
        height: 1px;
        flex: 1;
        background-color: #A7A6A6;
      }
    }
  }
  .to-get-started {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: ${p2r(20)};
    line-height:${p2r(28)};
    text-align: center;
    color: #6D6E6F;
    margin-top: ${p2r(15)};
    span.email {
      text-decoration-line: underline;
      color: #4275CE;
    }
  }
  h3 {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
  }
  .invalid-creds {
    color: var(--color-red-1);
    font-weight: 400;
    margin-bottom: 0rem;
    text-align: left;
    width: 100%;
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  .resend-note {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: ${p2r(12)};
    line-height: 1.5;
    text-align: center;
    color: #6D6E6F;
    margin-top: ${p2r(20)};
  }
  .password-note {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: ${p2r(12)};
    line-height: 1.5;
    text-align: left;
    color: #6D6E6F;
    margin-top: ${p2r(5)};
  }
  .contact-form {
    width: 100%;
    position: relative;
    margin: ${p2r(20)} 0;
    label {
      /* Email address */
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: ${p2r(12)};
      line-height: ${p2r(18)};
      color: #6D6E6F;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      &.error {
        color: #E33629;
      }
      &.bold-label {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: ${p2r(20)};
        line-height: 1.4;
        color: #1E1F21;
      }
    }
    .password-wrapper {
      position: relative;
      svg {
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        bottom: 0.6rem;
        right: 1rem;
      }
    }

    input, textarea {
      all: unset;
      display: block;
      width: 100%;
      border: 1px solid #9A9999;
      border-radius: 3px;
      height: ${p2r(42)};
      padding: 0 ${p2r(20)};
      margin-top: ${p2r(5)};
      font-size: 1rem;
      line-height: 1.5rem;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      box-sizing: border-box;

      font-style: normal;
      font-weight: 500;
      /* identical to box height, or 150% */
      color: #000000;
      opacity: 0.7;

      &[name="password"] {
        padding: 0 3rem 0 1rem;
      }
      
      &::placeholder {
        color: var(--color-gray-0);
        font-size: 1rem;
      }
      &.error {
        border: 1px solid var(--color-red-1);
      }
    }
    textarea::placeholder {
      transform: translateY(1rem);
    }
    .message-received {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      display: flex;
      justify-content: center;
      padding: 4rem 0 0 0;
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  button {
    width: 100%;
    height: 2.5rem;
    text-align: center;
    justify-content: center;
    font-size: 1rem;
  }
  .signup-link {
    cursor: pointer;
    color: var(--color-blue1);
    margin-bottom: 0;
  }
  .setup-complete-message {
    text-align: center;
    padding: 3rem 0 5rem;
  }
  .social-sign-in {
    display: flex;
    margin-top: ${p2r(40)};
    justify-content: center;
    align-items: center;
    border: 1px solid #9A9999;
    border-radius: 6px;
    height: ${p2r(50)};
    font-weight: 500;
    font-size: ${p2r(18)};
    line-height: ${p2r(18)};
    position: relative;
    cursor: pointer;
    svg {
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: calc(${p2r(25)} - 1rem);
      left: 0.6rem;
    }
  }
`;

const Signup = () => {
  const [ , setLoading ] = useAtom(loadingAtom);
  const [ trialData, setTrialData] = useState(null);
  const [isTrialEmailLinkSent, setIsTrialEmailLinkSent] = useState(false);
  const [failureMessage, setFailureMessage] = useState(null);
  const [formData, setFormData] = useState({
    email: { value: '', error: false },
    password: { value: '', error: false },
    fullname: { value: '', error: false }
  });
  useAuth(false);


  useEffect(() => {
    const url = new URL(window.location.href);
    const email = url.searchParams.get("trial");
    if (!IS_MOBILE && email) {
      formData.email.value = email;
      setTrialData({ email });
    } else {
      setTrialData(null);
    }
  }, []);

  const validateFormData = (data) => {
    const validatedData = {...data};
    let isValid = true;

    if (validateEmail(validatedData['email'].value)) {
      validatedData['email'].error = false;
    } else {
      validatedData['email'].error = 'Please enter a valid email address';
      isValid = false;
    }
    const password = validatedData['password'].value.trim();
    if (password.length < 8) {
      validatedData['password'].error = true;
      isValid = false;
    } else {
      validatedData['password'].error = false;
    }
    if ( validatedData['fullname'].value.trim() === '') {
      validatedData['fullname'].error = true;
      isValid = false;
    } else {
      validatedData['fullname'].error = false;
    }
    setFormData(validatedData);
    return isValid;
  } 

  const clearFormData = () => {
    setFormData({
      email: { value: '', error: false },
      password: { value: '', error: false },
      fullname: { value: '', error: false }
    });
  }

  const onInputChange = (e) => {
    const data = {...formData};
    const name  = e.currentTarget.name;
    const value = e.currentTarget.value;
    data[name].value = value;
    data[name].error = false;
    setFormData(data);
  }

  const onSubmitClick = async () => {
    if (validateFormData(formData)) {
      setLoading(true);
      const data = {};
      for (let key in formData) {
        data[key] = formData[key]?.value;
      }
      const duplicateEmail = await doesEmailExist(data?.email);
      if (duplicateEmail) {
        const data = {...formData};
        data['email'].error = 'An account with this email address already exists'
        setLoading(false);
      } else {
        await firebaseEmailTrial(data?.email);
        setIsTrialEmailLinkSent(true);
        setLoading(false);
      }
    }  
  }

  const onCompleteTrialClick = async () => {
    if (validateFormData(formData)) {
      setLoading(true);
      const email = formData?.email?.value || '';
      const password = formData?.password?.value || '';
      const fullName = formData?.fullname?.value || '';
      const user = await completeFirebaseEmailSignup(email, password, fullName, '', '');
      if (user === "alreadyUsed") {
        setFailureMessage("An account with this email address already exists.")
      } else if (user === "differentEmail") {
        setFailureMessage("Sorry! We couldn't verify your email.");
      }
      setLoading(false); 
    }  
  }

  const onGoogleClick = async () => {
    const user = await loginGoogleUser();
    if (user === "failure") {
      console.log("Login failed");
    } else {
      clearFormData();
      setLoading(false);  
      console.log(`You are now logged in as: ${user.email}`);
      //window.location.href = '/roles';
    }
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13 ) {
      onSubmitClick();
    }
  }

  const render = () => {
    if (trialData?.email) return (
      <Container>
        <h3>Welcome to Honest Resume</h3>
        <div className="to-get-started">You’re signing up 
        as <span className="email">{trialData?.email}</span></div>
        <GreyBar/>
        <div className="form-wrapper">
          <form className="contact-form" style={{marginTop: p2r(40)}}>
            <label className="bold-label">What’s your full name?</label>
            <input 
              name="fullname"
              placeholder="Full name"
              type="text"
              style={{marginBottom: p2r(10)}}
              onChange={onInputChange}
              onKeyDown={onKeyDown}
              className={formData?.fullname?.error ? 'error' : ''}
            />
            <label className="bold-label">Password</label>
            <input 
              name="password"
              placeholder="Password"
              type="password"
              onChange={onInputChange}
              onKeyDown={onKeyDown}
              className={formData?.password?.error ? 'error' : ''}
            />
            <div className="password-note">Create a password with at least 8 characters.</div>
          </form>
          <BlackButton onClick={onCompleteTrialClick}>Continue</BlackButton>
          {
            failureMessage &&
            <div className="invalid-creds">{failureMessage}</div>
          }
         
        </div>
      </Container>
    );
    if (isTrialEmailLinkSent) return (
      <Container>
        <h3>Verify your email address</h3>
        <div className="to-get-started">We have sent a verification 
        link to <span className="email">{formData.email.value}</span></div>
        <GreyBar/>
        <div className="form-wrapper" style={{marginTop: p2r(30)}}>
          <BlackButton onClick={onSubmitClick}>Resend Email</BlackButton>
          <div className="resend-note">*Click on the link in the email to 
          complete the verification process. You might need to check your spam folder</div>
        </div>
      </Container>
    );
    return (
      <Container>
        <h3>Welcome to Honest Resume</h3>
        <div className="to-get-started">To get started, please sign in</div>
        <GreyBar/>
        <div className="form-wrapper">
          <WhiteButton className='social-sign-in' onClick={onGoogleClick}>
            <GoogleIcon/> Continue with Google
          </WhiteButton>
          <div className="form-separator">
            <div className="form-separator-line"/>
            <div>or</div>
            <div className="form-separator-line"/>
          </div>

          <form className="contact-form" >
            {
              formData?.email?.error ?
              <label className="error">{formData.email.error}</label> :
              <label>Email address</label>
            }
            
            <input 
              name="email"
              placeholder="Email"
              type="text"
              onChange={onInputChange}
              onKeyDown={onKeyDown}
              className={formData?.email?.error ? 'error' : ''}
            />
          </form>
          <BlackButton onClick={onSubmitClick}>Continue</BlackButton>
        </div>
      </Container>
    );
  }
 
  return (
    <PageContainer 
      selected={'signup'}
      title={'Signup'}
      hideNavPanel={true}
      hideNavBar={true}
    >
      {render()}
    </PageContainer>
  );
};

export default Signup;