import React from "react";
import styled from 'styled-components';
import { GrEdit } from "react-icons/gr";

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    background-color: #c8eecf;
    width: 100%;
    border-radius: 0.2rem;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 1rem;
    font-size: 1rem;
    svg {
      cursor: pointer;
      display: none;
    }
    &:hover {
      svg {
        display: block !important;
      }
    }
    &.inactive {
      background-color: var(--color-red1);
    }
     
    .name {
      font-weight: 500;
      min-width: 10rem;
    }  
    .date {
      min-width: 10rem;
      text-align: right;
    }
    .required-preffered {
      span {
        margin: 0 1rem;
      }
    }
`

const RoleListItem = ({data, onClick}) => {
  const onItemClick = () => {
    onClick(data);
  }
  
  return (
    <Container className={data.is_active ? 'active' : 'inactive'}>
        <div className="name">{data.title}</div>
        <GrEdit color='black' size='0.9rem' onClick={onItemClick}/>
    </Container>
  );
}

export default RoleListItem;